

.avatar {
  &:hover .overlay-icon_top {
    opacity: 1;
  }
}

.avatar {
  .overlay-icon_top {
    position: absolute;
    height: 50%;
    width: 100%;
    top: 0;
    opacity: 0;
    transition: all .2s ease-in-out;
    cursor: pointer;
    border-bottom: 1px solid white;
  }
}

.cover-image {
  .init_cover_img {
    top: 7%;
  }
}

.room_container {
  .room_search_box {
    bottom: 40%;
  }

  .sort_container {
    bottom: 8%;

  }
}

.filter_btn {
  padding: 0.3rem 0.5rem !important;
}

.filter_btn button:focus {
  outline: none;
}

.filter_fa:hover {
  color: #F85A59;
  transform: translateY(0) scale(1.03);
}

.filter_btn:active {
  transform: translateY(1px);
}

.room_top_group_txt {
  position: relative;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  margin-top: 60px;
  display: inline-block;
}

.top_txt_wrapper {
  text-align: center;
  display: block;
  width: 100%;
  position: absolute;
  top: 15%;

  p {
    color: #b5b3b3;
  }
}

.top_btn {
  float: right;
  position: absolute;
  top: 20%;
  right: 0;
  display: inline-block;
  width: 30%;
  text-align: center;
  height: auto;
  margin-left: 50px;
  margin-top: 10px;
}

.top_btn > button {
  float: right;
  display: block;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
  background-color: white;
  color: black;
  font-weight: 500;
  font-size: 1em;
  font-weight: bold;
  border-radius: 20px;
  width: 80%;
  height: 60px;
  margin-bottom: 20px;

  a {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: black;
    text-decoration: none;
  }
}

.top_btn > button:hover {
  background: linear-gradient(to right, #F85A59, #F88E59);
  -webkit-text-fill-color: white;
}

.room_top_group_txt > .top_txt_wrapper > h1 {
  font-weight: bold;
  font-size: 3rem;
}

.room_top_group_txt > .top_txt_wrapper > p {
  font-size: 20px;
}

.room_top_group_list {

  display: block;
  position: relative;
  width: 100%;
  height: 700px;
}


.room_wizard-lottie-wrapper {
  padding-top: 4rem;

  .room_wizard-lottie {
    z-index: 1;
    width: 400px;
  }
}

.room_search_box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 3%;
  width: 100%;
  height: 50px;

  .search_box_wrapper {
    position: relative;
    height: 50px;
    width: 50%;
    margin: 0 auto;

    #room_searchText {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      width: 100%;
      height: 50px;
      border-radius: 20px;
    }


    .room_search_btn {
      position: absolute;
      right: 5px;
      top: 0;
    }

  }
}

.room_top_group_txt {
  .category_container {
    top: 65%;
  }
}

.room_tag {
  color: #b5b3b3;
  min-height: 40px;
  margin: 5px auto 0;
  text-align: center;
  font-size: 0.8em;
  width: 90%;
}


.room_search_btn:hover {
  //background: linear-gradient(to right, #F85A59, #F88E59);
  -webkit-text-fill-color: white;
}

.room_search_box_container > button {
  position: absolute;
  right: 0;
}

.group_grid_wrap {
  border-radius: 5px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.room_badge_wrap {
  position: absolute;
  z-index: 999;
  top: 5px;
  left: -1%;
}

.room_badge_category {
  position: relative;
  left: 4px;
}


.badge_txt {
  font-weight: bold;
  font-size: 20px;
  color: white;
  position: absolute;
  top: 5px;
  left: 20%;
}

.room_badge_img > img {
  width: 80px;
}

.group_btn_wrap {
  display: block;
  width: 100%;
}


.more_wrapper {
  margin-top: 70px;
  margin-bottom: 100px;
}

//.more_btn {
//  color: black;
//  background: white;
//  height: 50px;
//  width: 20%;
//  min-width: 180px;
//}
//
//.more_btn:hover {
//  background: linear-gradient(to right, #F85A59, #F88E59);
//  -webkit-text-fill-color: white;
//}

.room_search_box_container {
  width: 100%;

  .search_bar_error {
    font-size: 1rem;
    position: absolute;

  }
}


.room_list_wrapper {
  position: relative;

  margin-left: auto;
  margin-right: auto;
}

.person_count {
  position: absolute;
  right: 0;
  bottom: 0;
}

.menu {
  display: none;
  background: #34495e;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease;
  transform: translateX(-50%);

  a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 20px;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      font-size: 16px;
      border-bottom: 1px solid #fff;
      transition: all 0.25s ease;
      animation: fadeInRight .25s ease forwards;
      opacity: 0;

      &:hover {
        opacity: 0.8;
        transition: all 0.25s ease;
        background: #000;
        cursor: pointer;
      }
    }
  }
}

.open_container {
  display: inline-block;
  width: 75%;
}

.filter_active {
  background-color: #F85A59;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
  border-top-right-radius: 60px;
  float: left;
  position: sticky;
  top: 20%;
  display: inline-block;
  width: 20%;
  height: 80vh;
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease;
  transform: translateX(0);

}

.enter_btn {
  width: 70%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border: 1px solid #f1f1f1;

}

.enter_btn:hover {
  background: linear-gradient(to right, #F85A59, #F88E59);
  -webkit-text-fill-color: white;
}

.enter_room_modal_wrapper {
  .modal-content {
    display: block;
    margin: 0 auto;
    max-width: 1100px !important;
    border-radius: 40px;
  }

  max-width: 60% !important;
  margin-left: auto;
  margin-right: auto;
}

.enter_room_img_wrapper {
  display: flex;
  align-items: center;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;

  }

  padding-right: 0;
}

.enter_room_content_wrapper {
  padding-left: 0;
}

.enter_room_body {
  padding-top: 20px;
  padding-bottom: 40px;
}

.enter_room_content {
  max-height: 350px;
  overflow-y: scroll;
  min-height: 200px;
  border-radius: 10px;
  background-color: #ededed;
  width: 80%;
  margin: 20px auto;
}

.enter_room_btn_wrapper {
  display: block;

  .enter_mark {
    background: linear-gradient(to right, #F85A59, #F88E59);
    -webkit-text-fill-color: white;
  }

  button {
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #f1f1f1;
    width: 25%;
  }

  button:hover {
    background: linear-gradient(to right, #F85A59, #F88E59);
    -webkit-text-fill-color: white;
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.top_filter_btn {
  position: absolute;
  left: 2%;
  bottom: 0;

  img {

    width: 30px;
  }
}

.filter_modal_wrapper {
  max-width: 100vw;
  height: 100vh;
  padding: 0;
  vertical-align: middle;

  .modal-content {
    overflow-y: scroll;
    width: 60vw;
    height: 60vh;
    margin-left: auto;
    margin-right: auto;
    border-radius: 60px;

    .modal-body {
      .close_btn {
        color: white;
        font-weight: lighter;
        font-size: 60px;
      }

      background-color: #F85A59;
      border-radius: 58px;
    }
  }
}

.filter_wrapper {
  display: block;
  width: 80%;
  height: 60%;
  margin-left: auto;
  margin-right: auto;
  position: relative;

}

.filter_label {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.filter_category_wrapper {
  height: 25%;
}

.filter_sort_wrapper {
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .form-check-label {
    input {
      vertical-align: middle;
    }

  }

  .form-group {
    div {
      button {
        display: block;
        background-color: #F85A59;
        border: none;

      }
    }
  }
}

.filter_room_setting_wrapper {
  height: 25%;
}


.filter_submit_btn_wrapper {
  button {
    img {
      -webkit-filter: opacity(.5) drop-shadow(0 0 0 black);
      filter: opacity(.5) drop-shadow(0 0 0 black);
    }
  }

  button:hover {
    img {
      -webkit-filter: opacity(.5) drop-shadow(0 0 0 white);
      filter: opacity(.5) drop-shadow(0 0 0 white);
    }

    background: linear-gradient(to right, #F85A59, #F88E59);
    -webkit-text-fill-color: white;
  }
}

.form-group {
  .tags-input {
    border-radius: 4px;

    input {
      border-radius: 4px;
    }
  }
}

.room_create_img {
  object-fit: contain;
}


@media screen and (min-width: 1141px) and (max-width: 1540px) {

  .category_btn:nth-child(8) {
    margin-left: 0;
  }
}

@media screen and (max-width: 1140px) {
  .filter_label {
    font-size: 30px;
  }
  .filter_category_wrapper {
    height: 30%;
  }
  .filter_sort_wrapper {
    height: 30%;
  }
  .filter_room_setting_wrapper {
    height: 30%;
  }
  .filter_submit_btn_wrapper {
    height: 40%;

    button {
      margin-top: 40px;
      display: inline-block;
      vertical-align: bottom;
    }
  }
}

@media screen and (max-width: 600px) {
  .room_top_group_txt {
    .top_btn {
      img {
        display: none;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1140px) {


  .navbar-brand {
    float: left;
  }
  .toggle-icon-wrapper {
    margin-left: 20px;
  }
  .room_top_group_list {
    position: relative;
    width: 100%;
    height: 800px;
  }

  .top_btn {
    width: 100%;
    top: 40%;
    display: block;
    margin: 0;

    button {
      float: none;
      margin-left: 10px;
      margin-right: 10px;
      display: inline-block;
      width: 30%;
    }
  }

  .top_filter_btn {

    left: 5%;
    bottom: 0;
  }
  .room_wizard-lottie-wrapper {
    display: none;
    //right: 15%;
    //top: 20%;
    //position: absolute;
    //z-index: 1;
    //height: 1px;
    //
    //.room_wizard-lottie {
    //  z-index: 1;
    //}
  }
  #room_searchText {
    width: 50%;
  }


  .enter_room_modal_wrapper {
    max-width: 80% !important;
  }
  .enter_room_body {
    padding-top: 20px;
  }
  .enter_room_content_wrapper {
    margin-top: 15px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

  .room_search_box {
    top: 45%;
    justify-content: center;

    .room_search_box_container {
      width: 80%;
    }
  }
  .person_count {
    bottom: 24%;
  }
  .room_top_group_list {
    height: 800px;

    .category_container {
      top: 55%;
    }

    .sort_container {
      bottom: 0;

      .filter_sort_wrapper {
        width: 90% !important;
      }
    }
  }
  .room_top_group_txt {
    margin-top: 0;
    height: 100%;

  }
  .room_top_group_txt > h1 {
    font-size: 60px;
  }
  .room_top_group_txt > p {
    font-size: 15px;
  }
  .top_btn {
    width: 100%;
    top: 40%;
    display: block;
    margin: 0;

    button {
      float: none;
      margin-left: 10px;
      margin-right: 10px;
      display: inline-block;
      width: 40%;

    }
  }
  .top_filter_btn {

    left: 5%;
    bottom: 0;
  }

  .search_box_wrapper {
    width: 100% !important;
  }

  .room_wizard-lottie-wrapper {
    display: none;
  }


  .grid_list_wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  .enter_room_modal_wrapper {
    max-width: 90% !important;
  }
  .room_top_group_txt {
    .top_txt_wrapper {
      h1 {
        font-size: 40px;
      }

      p {
        font-size: 20px;
      }
    }
  }
  .top_btn {
    top: 30%;

    button {
      font-size: 0.9em;

    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .room_search_box {
    width: 100%;
    justify-content: center;

    .room_search_box_container {
      width: 80%;
    }
  }
  .room_list_card_wrapper {
    max-width: 80%;
    flex: 0 0 80%;
  }


  .navbar-brand {
    margin-left: 0;
  }
  .top_txt_wrapper {
    top: 15%;
  }

  .logo_txt {
    font-size: 20px;
  }
  .navbar-nav-icons {
    margin-right: 10px;
  }
  .top_btn {
    width: 100%;
    top: 30%;
    margin: 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;

    button {
      font-size: 0.8em;
      float: none;
      margin-left: 10px;
      margin-right: 10px;
      width: 44%;
    }
  }
  .top_filter_btn {
    left: 5%;
    bottom: 0;
  }
  .room_search_box {
    bottom: 50% !important;

    .room_search_box_container {
      .search_box_wrapper {
        padding-right: 0 !important;
        width: 100%;
      }

    }
  }
  .room_top_group_txt {
    margin-top: 0;
    height: 100%;
  }
  .room_top_group_txt > .top_txt_wrapper > p {
    font-size: 15px;
  }
  .room_top_group_txt > .top_txt_wrapper > h1 {
    font-size: 40px;
  }

  .room_top_group_txt > p {
    font-size: 15px;
  }


  .room_wizard-lottie-wrapper {
    display: none;
  }


  .room_top_group_list {
    display: block;
    position: relative;
    width: 100%;
    height: 660px;

    .category_container {
      top: 54%;
    }

    .sort_container {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      bottom: 0;

      .filter_sort_wrapper {
        width: 90% !important;
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column-reverse;
        align-items: flex-start;

        .room_form_check {
          vertical-align: middle;

        }
      }
    }
  }

  .grid_list_wrapper {
    margin-left: auto;
    margin-right: auto;
  }


  .enter_room_modal_wrapper {
    max-width: 100% !important;
  }
  .enter_room_content {
    min-height: 120px;
  }
  .enter_room_img_wrapper {
    img {
      width: 150px;
      height: 150px;
      margin-left: auto;
      margin-right: auto;
    }

  }

  .enter_room_btn_wrapper {

    button {
      width: 35%;
    }
  }

  .filter_modal_wrapper {
    .modal-content {
      width: 95vw;
      height: 85vh;

    }
  }
  .filter_room_setting_wrapper {
    margin-top: 20px;
  }
}

@media (min-width: 320px) and (max-width: 620px) {
  .enter_room_btn_wrapper {
    img {
      display: none;
    }
  }
}
