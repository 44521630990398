.group_detail_header {
  position: relative;
  .group_detail_header_tag_wrapper{
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    flex-direction: row;
    p{
      margin-left: 0.5rem;
      background-color: #a0a0a0;
      border-radius: 20px;
      padding: 5px 10px;
      font-size: 0.6rem;
      font-weight: bold;
      color: #fcfcfc;
    }
  }
  .header_backImg {
    position: absolute;
    z-index: -1;

    img {
      width: 80%;
      opacity: 50%;
    }
  }

  .header_wrapper {
    .group_img, .group_title, .group_reg_btn {
      padding: 0;
      margin: 3vh 0;
      position: relative;
    }

    .group_img {
      .group_detail_img_container{
        border: 1px solid #f1f1f1;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 13rem;
        height: 13rem;
      }
      .avatar {
        height: 12rem;
        width: 12rem;

        img {
          box-shadow: 10px 10px 15px grey;
        }
      }
    }

    .group_title {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .group_reg_btn {
      .reg_btn_wrapper {

        text-align: center;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .reg_btn {
          display: inline-block;
          background-color: white;
          color: black;
          font-size: 1rem;
          font-weight: bold;
          border: none;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          border-radius: 10px;
          width: 15rem;
          height: 50px;
        }

        .secession {
          background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
          font-size: 1rem;
          border:none;
          font-weight: bold;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          border-radius: 10px;
          width: 15rem;
          height: 50px;
        }

        .secession:hover {
          background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898!important;
          background-blend-mode: multiply,multiply!important;
          -webkit-text-fill-color: white!important;
        }

        .reg_btn:hover {
          background: linear-gradient(to right, #F85A59, #F88E59);
          -webkit-text-fill-color: white;
          border: none;
          box-shadow: 3px 3px 15px grey;
        }

        .reg_bottom_wrapper {
          margin-top: 20px;

          .reg_bottom_1, .reg_bottom_2{
            display: inline-block;
            text-align: center;
            span {
              font-weight: bold;
              color: black;
              font-size: 0.8rem;
            }
          }


          .reg_bottom_2 {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

.group_detail_persons_wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  .group_persons_item_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .group_persons_img_container{
      border: 1px solid #e3e3e3;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 115px;
      height: 115px;
      &:hover{
        background: linear-gradient(to right, #F85A59, #F88E59);
        outline: #F28482;
      }
    }
  }
}
.group_detail_tab {
  .nav-tabs {
    border-bottom: 1px solid #F85A59;
  }

  width: 100%;

  nav {
    .nav-link.active {
      border-color: #F88E59;
    }

    .active {
      background: linear-gradient(to right, #F85A59, #F88E59);
      -webkit-text-fill-color: white;
    }

    .nav-item {

      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

    }

    .nav-item:hover {
      color: #F85A59;
    }
  }
}
.ranking_badge_wrapper{
  flex: 0 0 5.33333%;
  max-width: 5.33333%;
}
@media (min-width: 320px) and (max-width: 400px) {
  .header_wrapper{
    margin-top: 18% !important;
  }
}
@media (min-width: 320px) and (max-width: 991px) {
  .header_wrapper{
    margin-top: 10%;
  }
  .group_reg_btn {
    display: flex;
    justify-content: center;

    .reg_btn_wrapper {
      display: flex !important;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      .reg_btn{
        margin-top: 0!important;

      }
      button {
        width: 40%;
        margin-bottom: 20px;
      }
    }
    .reg_bottom_wrapper{
      width: 100%;
    }


  }
  .history_item_wrapper{
    h6,label{
      font-size: 0.8rem!important;
    }
  .history_item_user{
    margin-left: 1rem;
  }
  }
  .study_time_txt{
    font-size: 1.3rem;
  }
  .ranking_badge_wrapper{
    flex: 0 0 10.33333%;
    max-width: 10.33333%;
  }
}

