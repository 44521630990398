.profile_chart_wrapper{
  min-height: 250px;
  position: relative;
  span{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
