.term_wrapper {
  .nav-item {
    cursor: pointer;
  }
}

.term_board_link {
  color: black;
}

.notice_wrapper {
  display: flex;
  margin-top: 4rem;
  flex-wrap: wrap;

  .notice_left_wrapper {
    //width: 30%;
    text-align: left;
  }

  .notice_right_wrapper {
    display: flex;
    flex-wrap: nowrap;
    //width: 70%;

    .term_board_no {
      vertical-align: middle;
      text-align: center;
      width: 10%;
    }

    .term_board_title {
      text-align: center;
      width: 70%;
      max-width: 500px;
    }

    .term_board_time {
      font-size: 0.8rem;
      vertical-align: middle;
      text-align: center;
      width: 20%;
    }
  }
}


.service_end_dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  .header_section{
    width: 100%;
    padding-bottom: 0.5rem;
    text-align: right;
  }
  .close_dialog_btn{
    background: none;
    border: none;
    cursor: pointer;
    font-size: 25px;
  }

  h5 {
    margin-bottom: 1rem;
  }

  .show_more_btn {
    width: 100%;
    background-color: #f88e59;
    color: white;
    border: none;
    padding: 0.5rem 0;
    font-weight: bold;
    font-size: 16px;
  }
}
