.home_detail {
  h4 {
    margin: 2rem 0;
  }
}

.group_introduction, .group_notice {
  margin-bottom: 10%;
}
.group_detail_home_title{
  font-weight: bold;
  padding-top: 1rem;
  font-size: 1.2rem;

}
.time_table_title{
  display: flex;
  justify-content: space-between;
  #limit_time_wrapper{

    font-size: 0.7rem;
  }
}
.group_introduction {
  .intro_title {
    vertical-align: top;
    font-weight: bold;
    font-size: 1rem;
    display: inline-block;
    width: 100px;
  }

  .intro_content {
    margin-left: 5%;
    display: inline-block;
    word-break: break-all;
    width: 70%;
    color: #727272;
  }

  div div {
    margin-bottom: 5%;
  }
}

.board_post {
  margin-bottom: 1rem;
}
.rbc-time-header-gutter{
  width: 53.6328px;
  min-width: 53.6328px;
  max-width: 53.6328px;
}

.rbc-time-header.rbc-overflowing{
  border: none !important;
  margin: 0 !important;
}
.rbc-timeslot-group{
  .rbc-time-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    .rbc-label {
      padding: 5px 5px;
      color: rgba(112,112,112,0.8);
    }
  }
}
.rbc-event-label{
  display: none!important;
}
.rbc-events-container{
  margin-right: 0 !important;
  .rbc-event {
    border-top: 0.5px solid white !important;
    border-bottom: 0.5px solid white !important;
    border-radius: 0!important;
    left: 0!important;
    width: 100% !important;
    padding: 0;
    z-index: 10;
  }
}
.group_detail_content_wrapper{
  min-height: 250px;
  max-height: 400px;
  background-color: #fafafa;
  overflow-y: scroll;
  padding: 1rem;
  span{
    width: 90%;
    word-break: break-all;
    padding: 1rem;
  }
}
.__react_component_tooltip.show{
  z-index: 999!important;
}

.rbc-event-content{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width:100px;
  height: 80%;

  a{
    color: white!important;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      font-size: 0.5rem;
      padding: 0 10px;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

    }
  }
}

.rbc-header{
  padding: 5px 0!important;
  border-bottom: none!important;
  color: rgba(112,112,112,0.8);
}

.rbc-today{
  background-color: rgb(252,237,237) !important;
}
.rbc-current-time-indicator{
  background-color: #F85A59!important;
}


.rbc-time-content::-webkit-scrollbar{
  display: none;
  width: 0 !important;
}
.group_connecting {
  .connecting_container {
    padding: 10px 0 0 20px;
    border: 0.5px solid rgba(0,0,0,0.3);
    border-radius: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
  }
}

.connecting_container{
  div{
    display: inline-block;
    margin: 20px;
  }
  .nickname{
    width: 65px; //이미지와 같은 크기
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
    margin: 0;
    padding: 5px 0 0 0;
    color: rgba(0,0,0,0.4);

  }
}


.connectingItem{
  width: 65px;
  height: 65px;
}

.nicknameTooltip{
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  font-size: 0.8em;
  color: white;
  background: rgba(0,0,0,0.5);
  white-space:nowrap;
}
.nickname:hover .nicknameTooltip {
  display: inline-block;
}
.group_detail_board_wrapper{
  min-height: 340px;
  position: relative;
  .board_no_top{
    flex: 0 0 10%;
    width: 10%;
  }
  .board_no{
    font-size: 0.8rem;
    flex: 0 0 10%;
    width: 10%;
  }
  .board_title{
    font-weight: bold;
    cursor: pointer;
    margin: 0!important;
    flex: 0 0 70%;
    width: 65%;
    max-width: 400px;
  }
  .board_time_top{
    flex: 0 0 20%;
    width: 25%;
  }
  .board_time{
    font-style: italic;
    flex: 0 0 20%;
    color: #5a5858;
    width: 20%;
  }
  .time_item{
    font-size: 0.8rem;
  }
}
.group_detail_board_detail{
  .modal-header{
    display: flex;
    flex-wrap: nowrap;
    .modal-title{
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .card-body{
    padding: 0!important;
    margin: 0!important;
    max-width: 500px;

    .quill{
    display: block;
      margin: 0 auto;

  }
  }

}
.group_detail_board_none{

  span{
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%,-50%);
  }
}
.group_sign_btn{
  color: black!important;
  &:hover{
    color: white;
  }
}
