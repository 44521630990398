//.groupDetail_noticeModal{
//  position: relative;
//  .modal_content{
//    position: absolute!important;
//    width: 100%;
//    height: 1px!important;
//    padding-bottom: 52.25% !important;
//  }
//}

@media (min-width: 1024px) {
  .groupDetail_noticeModal{
    max-width: 40% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 576px) and (max-width: 1024px) {
  .groupDetail_noticeModal{
    max-width: 60% !important;
  }
}
//@media screen and {
//
//}