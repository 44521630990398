.navbar_notification_toggle {
  margin-right: 2rem;

  .navbar_notification_icon {
    width: 30px;
    height: 30px;
  }
}

.notification_list {
  margin-right: 2rem !important;
  width: 350px;

  .dropdown-item {
    padding: 0 !important;
  }

  .notification_item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .notice_img {
      text-align: left;
      float: left;
      padding: 0.2rem 0.4rem;
    }

    .notice_read {
      font-weight: lighter;
      color: #a4a4a4;
    }

    .notice_not_read {
      font-weight: bold;
      color: #545454;
    }

    .notice_content {
      width: 100%;
      word-break: break-all;
      white-space: break-spaces;
      padding: 0 0.25rem;
      display: flex;
      flex-direction: column;

      .notice_date {
        font-size: 0.7rem;
        padding: 0.3rem 0;
        text-align: right;

        .notice_close_icon {
          width: 20px;
          height: 20px;
          margin-right: 0.3rem;
          fill: #F85A59;
        }
      }
    }
  }

  .zero_notification {
    text-align: center;

    div {
      padding: 2rem;
    }

  }

  .notification_footer_btn {
    padding: 1.2rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    button {
      border-radius: 14px;
      background: none;
      width: 40%;
      border: 1px solid #a5a5a5;
      padding: 0.3rem;

      &:hover {
        border: none;
        color: white;
        background: #ff7979;
      }
    }
  }

}

@media (max-width: 768px) {
  .notification_wrapper {
    position: static !important;

    .notification_list {
      width: 100% !important;
      right: 0 !important;
      margin-right: 0!important;
    }
  }
}
