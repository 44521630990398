.myGroup_header {
  position: relative;

  .myGroup_header_backImg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: fit-content;
    text-align: right;
    img {
      width: 70%;
      opacity: 0.5;
    }
  }

  .myGroup_header_wrapper {
    .myGroup_title, {
      padding: 6vh 0 5vh 0;
    }

    .myGroup_title {
      text-align: center;

      div {
        vertical-align: middle;
        display: inline-block;

        h1 {
          color: black;
          font-weight: bold;
        }
      }
    }
  }
}
.no_have_group{
  margin-right: auto;
  margin-left: auto;
  margin-top: 10%;

  .btn_group_list{
    background-color: #fff;
    color: #F85A59;
    border-color: #F85A59;
    border-radius: 20px;
    padding: 0.8rem 3rem;
  }
  .btn_group_list:focus{
    outline: none;
  }
  .btn_group_list:hover{
    background-color: #FF4E50;
    border-color: none;
    color: white;
    font-weight: bold;
    transform: translateY(0) scale(1.05);
  }
}
.myGroup_card {
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  //background: linear-gradient(to right, #ffa6a6, #fdc5aa);
  .avatar {
    height: 5rem;
    width: 5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  }
  &:hover {
    border: 1px solid #F88E59;
    transform: translateY(0) scale(1.03);
  }

  .faArrow:hover {
    color: lightgrey;
  }
  &:active {
    transform: translateY(1px);
  }

  .group_tag{
    min-height: 15px;
    color: #b5b3b3;
    -webkit-line-clamp: 2;
  }

}


@media (min-width: 320px) and (max-width: 400px) {
  .header_wrapper {
    margin-top: 18% !important;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .header_wrapper {
    margin-top: 10%;
  }
}


