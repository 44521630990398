//.groupManageNotice>div{
//  display: flex;
//}

.board_post{
  font-size: 1rem;
  display: flex;
  align-items: center;
  vertical-align: center;
  //.post_title span{
  //  padding-left: 20px;
  //}
  .post_udContainer{
    display: flex;
    justify-content: flex-end;
    .post_U,.post_D{
      width: 62px;
    }
    //.post_U{
    //  margin-right: 20px;
    //}
    //.post_D{
    //  margin-right: 5px;
    //}
  }
}

.manageButton{
  background-color: white!important;
  color: lightcoral!important;
  border-color: lightgrey!important;
}
.manageButton:hover{
  background-color: white!important;
  color: #F85A59!important;
  border-color: #F85A59!important;
}