.board_item_wrapper {
  align-items: center;

  :hover {
    background-color: #fff7f7;
  }
}

.reply_input {
  display: block;
  margin: 1rem auto !important;

  width: 95%;
}

.board_search_wrapper {
  justify-content: center;

  #searchType {
    min-width: 100px;
  }

  .form-group {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.board_category_wrapper {
  min-width: 100px;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  text-align: right;
  align-items: center;
  width: 100%;

  .write_btn {
    background-color: #f88e59;
    color: white;
    border: none;
  }
}


.profile_link_btn {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #F28482;
  }
}

.board_search_btn {
  max-height: 36px;
}

.board_detail_card {
  margin-top: 2rem;
  width: 100%;

  .detail_header {
    width: 100%;

    h3 {
      padding-left: 2rem;
    }

    .detail_info {
      margin-top: 1rem;
      padding-left: 2rem;
      font-size: 0.8rem;
      align-items: center;

      img {
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }
    }
  }

  .detail_body {
    min-height: 300px;
  }

}

#reply_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.board_item_category {
  width: fit-content;
  padding: 0 !important;
  font-size: 0.9rem;
}

//@media (min-width: 769px) {
//  .board_item_category {
//    flex: 0 0 22% !important;
//    max-width: 22% !important;
//
//    span {
//      font-size: 0.8em;
//    }
//
//  }
//}

@media (min-width: 320px) and (max-width: 400px) {
  .board_item_title {
    vertical-align: middle;
    font-size: 0.7em !important;
  }

  .board_item_bottom {
    font-size: 0.6em;

    .board_item_date {
      flex: 0 0 30%;
      max-width: 30%;
    }

  }
  .mobile_view_cnt_wrapper {
    font-size: 0.6em !important;
  }

}

@media (min-width: 320px) and (max-width: 576px) {
  #kb_img_wrapper {
    img {
      width: 70px;
      height: 70px;
    }

  }
  .not_mobile_footer {
    display: none;
  }

  .detail_header {
    margin-top: 1rem !important;

    span {
      font-size: 0.5em;
    }
  }
}


.inquiry_board_wrapper {
  margin-bottom: 3rem;
}

.inquiry_board_tbody {
  td {
    cursor: pointer;
    font-weight: bold;
  }
}

.wait_res {
  color: grey;
}

.finish_res {
  color: #f88e59;
}

.inquiry_reply_wrapper {
  margin-top: 2rem;
  border: 2px solid #f1f1f1;
  min-height: 400px;
}

.pagingnation_wrapper {
  p {
    margin: 0;
  }
}

.notexist_board_wrapper {
  width: 100%;
  min-height: 300px;
  position: relative;

  .text_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-weight: bold;
      font-size: 1.1rem;
      color: #9e9e9e;
    }

    button {
      margin-top: 1rem;
      padding: 0.4rem 1.6rem;
      background-color: #f88e59;
      color: white;
      border: none;
      cursor: pointer;
    }
  }

}

@media (min-width: 320px) and (max-width: 768px) {
  .board_category_container {
    max-width: 100% !important;

    .board_name {
      display: none !important;
    }

    .board_top {
      display: none;
    }

    .board_item_wrapper {
      display: flex;
      justify-content: space-between;

      .board_item_content {
        width: 90%;
      }

      .mobile_view_cnt_wrapper {

        text-align: center;
        width: 10%;
        display: flex !important;
        flex-direction: column;
        background-color: #f1f1f1;
        border-radius: 10px;
        font-size: 0.8em;

        span {
          padding: 0.2em 0;
        }
      }
    }

    .board_item_no {
      display: none !important;
    }

    .board_item_category {
      text-align: left !important;
      //max-width: 35%;
      font-size: 0.7em !important;
      margin-right: 0.4rem;
    }

    .board_item_title {
      max-width: 75%;
    }

    .board_item_bottom {
      font-size: 0.6em;
      padding-left: 0 !important;

      .board_item_footer {
        align-items: center;
      }

      .board_item_nickname {
        padding: 0 0.5em;
        text-align: left !important;
        max-width: fit-content;
      }

      .board_item_date {
        padding: 0 !important;
        text-align: left !important;
      }

      .board_item_view_cnt {
        display: none;
      }
    }

    .board_detail_container {
      margin-top: 70px;

    }
  }
}
