
.caRoom_cardBody{
  h6{
    padding: 0.5em 0;
  }
}
#timer_picker_wrapper{
  display: block;
  .ant-picker{
    width: 100%;
    margin-bottom: 1em;
  }
}

.modal-body{
  display: flex;
  justify-content: center;
  flex-direction: column;
  .weekly_wrapper{
    display: flex;
    flex-direction: row;
    .custom-checkbox{
      margin-right: 1rem;
    }
  }
  .ant-picker{
    width: 100% !important;
  }
}
.make_room_btn{
  height: 40px;
  width: 136px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
}
