.rtc_top_nav {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  z-index: 999;
  //background: linear-gradient(to right, #F28482, #F2A682);
  .top_nav_content{
    display: flex;
    align-items: center;
    height: 100%;
    img {
      width: 80px;
    }
    span {
      cursor: text;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
}
