

.bg_main {
  background: linear-gradient(to right, #F85A59, #F88E59);
}

.group_container {
  width: 100%;
  height: 100%;
}

.top_group_txt {
  margin-top: 60px;

}

.top_group_txt > h1 {
  font-size: 2.5em;
  span{
    color: #ff96a2;
  }
}

.top_group_txt > p {
  color: #b5b3b3;
  font-size: 1rem;
}

.top_group_list {
  display: block;
  position: relative;
  width: 100%;
  height: 620px;
}

.group_banner_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;

  img {
    width: 400px;
    height: 400px;
  }
}

.wizard-lottie-wrapper {
  right: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  height: 1px;

  .wizard-lottie {
    z-index: 1;
    width: 600px;
  }
}

.group_search_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  //position: absolute;
  //z-index: 999;
  //margin-top: 20px;
  //top: 40%;
  .search_box_container {
    width: 100%
  }

  .group_search_box_wrapper {
    margin: 0 auto;
    position: relative;
    height: 50px;
    width: 50%;

    #group_searchText {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 50px;
      border-radius: 20px;
    }


    .group_search_btn {
      position: absolute;
      right: 5px;
      top: 0;
    }

  }
}

.search_box_container > input {
  margin-left: auto;
  margin-right: auto;
}

#searchText {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  width: 50%;
  height: 50px;
  border-radius: 20px;
}

.category_container {
  position: absolute;
  top: 65%;
  width: 100%;
  z-index: 999;
}

.group_category {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.category_btn_group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.category_btn {
  border: 1px solid #f1f1f1!important;
  margin-right: 20px;
}


.category_btn:hover {
  background: linear-gradient(to right, #F85A59, #F88E59);
  -webkit-text-fill-color: black;
}


.search_btn:hover {
  background: linear-gradient(to right, #F85A59, #F88E59);
  -webkit-text-fill-color: white;
}

.search_box_container > button {
  position: absolute;
  right: 0;
}

//.group_grid_wrap {
//
//  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
//}

.group_list_card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-sizing: border-box;
  margin: 0 10px;
}

.group_btn_wrap {
  display: flex;
  width: 100%;
}

.group_btn {
  font-weight: 1000;
  width: 70%;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  color: black;
  background: white;
  &:hover {
  background: linear-gradient(to right, #F85A59, #F88E59);
  color: white;
}
}



.more_wrapper {
  margin-top: 70px;
  margin-bottom: 100px;
}

.not_found_list {
  min-height: 300px;
}

.more_btn {
  border-color: #F85A59;
  border-radius: 20px;
  border-width: 1px;
  color: black;
  background: #fff;
  height: 50px;
  width: 20%;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
}

.more_btn:hover {
  background: linear-gradient(to right, #F85A59, #F88E59);
  -webkit-text-fill-color: white;
}

.search_bar_error {
  font-size: 20px;
  position: absolute;
  left: 0%;
  top: 100%;
}

.group_list_wrapper {
  position: relative;
  display: inline-block;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}

.person_count {
  position: absolute;
  right: 0;
  bottom: 0;
}

.sort_container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;

  .filter_sort_wrapper {
    width: 70%;
    float: right;
    height: auto;
  }


  .form-group {
    div {
      margin: 0;
      padding: 0;
    }

    button {
      float: right;
      padding: 0;
      margin: 0;
      border: none;
      height: auto;
      background: linear-gradient(to right, #F85A59, #F88E59);
    }
  }
}

.group_card_wrapper {
  position: relative;

  .group_list_card {
    border-radius: 0.7em;

    .group_card_top {
      -webkit-text-fill-color: white;
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      border-top-left-radius: 0.7em;
      border-top-right-radius: 0.7em;
    }

    .group_card_detail {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      background-color: #f1f1f1;
    }
    .group_list_card_title {
      display: block;
      margin: 0 auto;
      width: 90%;

      a {
        text-decoration: none !important;

      }
    }

    .group_list_card_tag {
      color: #b5b3b3;
      min-height: 40px;
      margin: 0 auto;
      text-align: center;
      width: 80%;
      padding: 0.7em 0 0 0;
    }
  }

}



.group_detail_board_body {
  position: relative;
  min-height: 200px;

  .empty_group_board {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.group_list_enter_btn {
  width: 50% !important;
  border-color: #707070!important;
  border-radius: 25px!important;
  color: #707070!important;
  font-size: 0.8rem!important;

  &:hover {
    border-color: #FFA191;
    color: white;
    background: linear-gradient(to right, #F85A59, #F88E59);
  }
}

@media (min-width: 1200px) {
  .group_detail_board_body {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    overflow: scroll;
    overflow-x: hidden;

    .empty_group_board {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}


.one_line_txt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.two_line_txt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.group_notice_title {
  .modal-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow-wrap: break-word;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}

//.group_detail_tab{
//  .rbc-time-header-gutter{
//    width: 85.25px;
//    min-width: 85.25px;
//    max-width: 85.25px;
//  }
//}
//---------------------------------------------------------

@media (min-width: 320px) {
  .category_container {
    top: 55%;
  }
  .filter_sort_wrapper {
    width: 100% !important;

    .form-check {
      text-align: left;
      padding: 0;
      left: 0;
    }
  }
}


@media (min-width: 768px) and (max-width: 1140px) {
  .navbar-brand {
    float: left;
  }
  .toggle-icon-wrapper {
    margin-left: 20px;
  }
  .top_group_list {
    position: relative;
    width: 100%;
    height: 700px;
  }
  .category_container {
    position: absolute;
    top: 55%;
    width: 100%;
    z-index: 999;
  }
  .wizard-lottie-wrapper {
    right: 15%;
    top: 20%;
    position: absolute;
    z-index: 1;
    height: 1px;

    .wizard-lottie {
      z-index: 1;
      width: 500px;
    }
  }
  .group_category {
    max-width: 100%;
  }


  .category_btn:nth-child(8) {
    margin-left: 0;
  }
  .group_list_wrapper {

    width: 33.333%;

  }

}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .toggle-icon-wrapper {
    margin-left: 20px;
  }
  .person_count {
    bottom: 24%;
  }
  .top_group_list {
    position: relative;
    width: 100%;
    height: 680px;
  }

  .top_group_txt > h1 {
    font-size: 35px;
  }
  .top_group_txt > p {
    font-size: 15px;
  }

  .group_search_box {
    top: 25%;

    .group_search_box_wrapper {
      width: 75%;
    }
  }
  #searchText {
    width: 82%;
  }

  .wizard-lottie-wrapper {
    right: 15%;
    top: 30%;
    position: absolute;
    z-index: 1;
    height: 1px;

    .wizard-lottie {
      z-index: 1;
      width: 400px;
    }
  }
  .group_category {
    max-width: 100%;
  }
  .category_container {
    position: absolute;
    width: 100%;
    z-index: 999;
  }

  .category_btn_group {
    margin-left: auto;
    margin-right: auto;
  }


  .grid_list_wrapper {
    margin-left: auto;
    margin-right: auto;
  }
  .group_list_wrapper {

    width: 49.999%;

  }
  .filter_sort_wrapper {
    width: 100%;

  }
}

@media screen and (min-width: 480px) and (max-width: 580px) {
  .category_btn {
    margin-right: 5px;
    font-size: 14px;
  }

  .group_list_container {
    margin: 0 auto;
    width: 70%;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .toggle-icon-wrapper {
    margin-left: 20px;
  }
  .navbar-brand {
    margin-left: 0;
  }
  .logo_txt {
    font-size: 20px;
  }
  .navbar-nav-icons {
    margin-right: 10px;
  }
  .top_group_txt > p {
    font-size: 10px;
  }
  .top_group_txt > h1 {
    font-size: 35px;
  }

  .top_group_txt > p {
    font-size: 15px;
  }

  .group_list_container {
    margin: 0 auto !important;
    width: 85%;
  }

  .wizard-lottie-wrapper {
    right: 15%;
    top: 25%;
    position: absolute;
    z-index: 1;
    height: 1px;

    .wizard-lottie {
      z-index: 1;
      width: 250px;
    }
  }
  .category_btn {
    margin-right: 5px;
    font-size: 14px;
  }

  .top_group_list {
    display: block;
    position: relative;
    width: 100%;
    height: 680px;
  }
  .group_category {
    max-width: 100%;
    margin: 0;
  }
  .category_btn_group {
    margin-left: auto;
    margin-right: auto;
  }
  .category_btn {
    font-size: 13px;
    margin-left: auto;
    margin-right: auto;

  }
  .category_btn:nth-child(8) {
    margin-left: 0;
  }
  .group_search_box_wrapper {
    width: 80% !important;
  }
  .grid_list_wrapper {
    margin-left: auto;
    margin-right: auto;
  }
  .group_list_wrapper {

    width: 99%;

  }
  .filter_sort_wrapper {
    width: 100%;
    justify-content: flex-start!important;
    flex-direction: column-reverse!important;
    align-items: flex-start!important;
  .form-check{
    margin-left: 0!important;
  }
  }
}
