
.banner_wrapper {
  .container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: center;

    #landing_title {
      font-size: 3.5rem;
      color: white;
      font-weight: 900;
    }

    .now_study_btn {
      display: block;
      margin: 0 auto;
      max-width: 500px;

      span {
        padding: 0.5rem;
      }
    }

    .landing_banner_bottom {
      padding-top: 12rem;
    }

    @media (max-width: 570px) {
      #landing_title {
        font-size: 2.2rem;
      }
      .now_study_btn {
        font-size: 0.7rem;
      }
      .landing_banner_bottom {
        padding-top: 3rem;
      }
    }
  }

}


.landing_category_wrapper {
  width: 100%;
  margin: 0 auto;

}

.now_study_btn {

  &:hover {
    color: black !important;
    background-color: white !important;
  }
}

.landing_category_container,
.landing_room_container {
  min-height: 600px !important;
}

.slick-slider {
  min-height: 150px;
  max-height: 300px;

  .slick-list {
    height: 100%;

    .slick-track {
      height: 200px;

      .slick-slide {
        max-width: 240px;
        max-height: 200px;
      }
    }
  }
}

.landing_room_card_wrapper {
  max-width: 243px;
  position: relative;
  cursor: pointer;
  padding-bottom: 70%;

  .grid_list_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 243px;
    max-height: 175px;
    height: auto;

  }
}

.landing_category_card_wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.landing_category_card {
  cursor: pointer;
  border: 0.5px solid white;
  height: 0;
  padding-bottom: 60%;

  .card-body {
    padding: 0;
  }

  .card-title {
    display: block;
    margin: 0 auto;
    width: 35%;
  }

  a {
    text-decoration: none;
  }

  img {
    margin-top: 5px;
    max-width: 100%;
    height: auto;
  }

  .card-text {
    margin-top: 0.5em;
    font-size: calc(0.26vw + 11.08px);
  }
}

.landing_category_card:hover {
  background-color: #f1f1f1;
  border: 0.5px solid orange;
}

.landing_badge_wrap {
  position: absolute;
  z-index: 999;
  top: 1%;

  .badge_category {
    position: relative;
    right: 2.8px;
  }

  img {
    max-width: 80px;
  }
}

.mark_card_content_wrapper {
  display: none;
}

.landing_mark_img_content {
  border-bottom-left-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;

  .landing_mark_title {
    font-size: 0.9rem;
    text-align: left;
    overflow: hidden;
    -webkit-line-clamp: 1;
    overflow-wrap: break-word;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    width: 99%;
  }


}

.mark_card_wrapper {
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;;

  img {
    max-width: 243px;
    max-height: 175px;
    border-radius: .25rem;
  }
}

.landing_mark_person {
  border-top-right-radius: .25rem;
  font-size: 0.6rem;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
}

.bg_app {
  background-position: center;
  position: relative;
  background-size: cover;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;

  #bg_cover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(50, 61, 102, 0.7);
  }

  .app_wrapper {
    width: 100%;
    min-height: 500px !important;
    .landing_app_left{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: bold;
      #app_title{
        font-size: 3rem;
        padding: 1rem 0;
      }
      span:nth-child(2){
        color: #ececec;
      }
    }
    .landing_app_right{
      text-align: right;
      padding-right: 0!important;
      img{
        object-fit: cover;
      }
    }
  }

}

.landing_group_card_wrapper {
  max-width: 250px;

  height: 0;
  position: relative;
  padding-top: 5%;
  border-radius: 10px;

  .landing_group_card_wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      margin-top: 21px;
      margin-left: 16px;
    }

    .group_tag {
      -webkit-line-clamp: 2;
    }

    .category_badge {
      font-size: 8px;
      margin-right: 13px;
    }

    .landing_group_card_detail_row {
      border-radius: 25px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      flex-wrap: nowrap !important;

      span {
        font-size: 0.8em;
      }
    }

    .landing_group_mark_person {
      margin-top: 15px;
      font-size: 0.5rem;
      color: #414141;
      font-weight: 600 !important;
    }

    .user_icon {
      margin-top: 15px;
      width: 11px;
    }

    .title_tag_wrapper {
      margin-left: 21px;
      margin-top: 10px;

      .one_line_txt {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow-wrap: break-word;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .two_line_txt {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow-wrap: break-word;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      h5 {
        font-size: 0.9rem;
        font-weight: bold !important;
      }

      .faArrow {
        height: 15px;
      }

      .group_tag {
        font-size: 0.7rem;
      }
    }
  }
}

.slick-arrow {
  background: linear-gradient(to right, #F85A59, #F88E59) !important;
  //color: white !important;
}

.slick-next {
  text-decoration-color: white;
}

.card_bottom_count_wrapper {
  h5 {
    font-size: 1em;
  }

  small {
    font-size: 0.5em;
  }
}


.open_banner_bg_img{
  height: 240px;
  .open_banner_container {
    min-height: 300px !important;

    .monitor_img {
      top: -40px;
    }
    .deco_bottom {
      top: 165px;
    }

    .text_img {
      left: 60px;
      top: 35px;
    }
  }
}

@media (min-width: 320px) and (max-width: 400px) {
  .landing_group_card_wrapper {
    .group_card_img_container {
      display: none;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .landing_category_card_wrapper {
    .card-text {
      margin-top: 1rem;
    }
  }
  .landing_app_right{
    text-align: center!important;
    img{
      width: 95%;
      height: 250px;

    }
  }
  .landing_group_card_wrapper {
    .category_badge {
      text-align: right;
    }

    .card_top_txt_wrapper {
      width: 100% !important;
      text-align: center;
      margin-top: 0;
      display: flex;

      h4 {
        justify-content: flex-end;
        width: 90%;
        font-size: 1.2em;
      }
    }


  }
}

@media (min-width: 320px) and (max-width: 990px) {
  .card_top_txt_wrapper {

    h4 {
      text-align: center !important;
    }
  }
  .landing_group_card_detail_row {
    flex-wrap: nowrap;
  }

  .card_bottom_txt_wrapper {
    .content_txt,
    .tag_txt {
      padding-left: 0.5rem !important;
      font-size: 0.8rem;
    }
  }
  .landing_app_right{

    img{
      width: 100%;
      height: 100%;
    }
  }

}

//오픈베타배너 반응형
@media (max-width: 389px) {
  .open_banner_bg_img {
    height: 80px;
    .open_banner_container {
      .monitor_img{
        top: -10px;
        left: 10px;
        background-size: 110px 90px;
      }
      .text_img {
        left: -5px;
        top: 10px;
        background-size: 105px 60px;
      }
      .deco_top,.deco_bottom{
        visibility: hidden;
      }


    }
  }
}
@media (min-width: 389px) and (max-width: 432px) {
  .open_banner_bg_img {
    height: 120px;
    .open_banner_container {
      .monitor_img {
        top: -20px;
        left: 10px;
        background-size: 170px 140px;
      }

      .text_img {
        left: -5px;
        top: 15px;
        background-size: 160px 90px;
      }

      .deco_top,.deco_bottom {
        visibility: hidden;
      }


    }
  }

}
@media (min-width: 432px) and (max-width: 650px) {
  .open_banner_bg_img {
    height: 130px;
    .open_banner_container {
      .monitor_img {
        top: -20px;
        left: 10px;
        background-size: 190px 150px;
      }

      .text_img {
        left: 10px;
        top: 15px;
        background-size: 180px 100px;
      }

      .deco_top,.deco_bottom {
        visibility: hidden;
      }
    }
  }
}
@media (min-width: 650px) and (max-width: 990px) {
  .open_banner_bg_img {
    height: 180px;
    .open_banner_container {
      .monitor_img {
        top: -40px;
        left: 5px;
        background-size: 280px 220px;
      }

      .deco_top {
        background-size: 65px 50px;
      }

      .deco_bottom {
        top: 130px;
        background-size: 65px 50px;
      }

      .text_img {
        left: 40px;
        top: 20px;
        background-size: 250px 140px;
      }
    }
  }

}

@media (min-width: 973px) and (max-width: 1080px) {
  //.landing_group_card_wrapper {
  //  max-width: 220px;
  //  max-height: 150px;
  //  position: relative;
  //  padding-bottom: 50%;
  //
  //}
  .open_banner_bg_img {
    height: 180px;
    .open_banner_container {
      .monitor_img {
        top: -76px;
        background-size: 350px 255px;
      }

      .deco_top {
        background-size: 75px 60px;
      }

      .deco_bottom {
        top: 120px;
        background-size: 75px 60px;
      }

      .text_img {
        left: 90px;
        top: 20px;
        background-size: 250px 140px;
      }
    }
  }
}
